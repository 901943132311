<template>
   
    <v-app>
        <h1>
        MODULO CLIENTE
       </h1>
       <v-main>
       
          <v-container>
<form ref="form"
 @submit.prevent="insertar_cliente()">
 <v-text-field
    v-model="nit"
      name="nit"
      label="nit"
      type="text"
      placeholder="nit"
      required
   ></v-text-field>   
 <v-text-field
    v-model="razon_social"
      name="razon_social"
      label="razon_social"
      type="text"
      placeholder="razon_social"
      required
   ></v-text-field>
   
    <v-text-field
      v-model="direccion"
      name="direccion"
      label="direccion"
      type="text"
      placeholder="direccion"
      required
   ></v-text-field>

  

   <v-text-field
    v-model="secretaria"
      name="secretaria"
      label="secretaria"
      type="text"
      placeholder="secretaria"
      required
   ></v-text-field>

   <v-text-field
    v-model="contacto_1"
      name="contacto_1"
      label="contacto_1"
      type="text"
      placeholder="contacto_1"
      required
   ></v-text-field>

   <v-text-field
    v-model="contacto_2"
      name="contacto_2"
      label="contacto_2"
      type="text"
      placeholder="contacto_2"
      required
   ></v-text-field>

   <v-text-field
    v-model="contacto_3"
      name="contacto_3"
      label="contacto_3"
      type="text"
      placeholder="contacto_3"
      required
   ></v-text-field>

   <v-text-field
    v-model="Barrio_idBarrio"
      name="Barrio"
      label="Barrio"
      type="text"
      placeholder="Barrio"
      required
   ></v-text-field>

  
   <div class="red--text"> </div>
   <v-btn type="submit" class="mt-4" color="#F57C00" style="color:white" value="log in">Crear Cliente
    <!-- {{isRegister ? stateObj.register.name : stateObj.login.name}} -->
</v-btn>
   <div class="grey--text mt-4" >
     
   </div>
</form>
</v-container>
       </v-main>
    </v-app>
 </template>
 <script>
 import axios from 'axios';
  export default {
    name: "App",
    data() {
      return {
        nit: "",
        razon_social: "" ,
        direccion: "" ,         
        secretaria: "" ,        
        contacto_1:"",
        contacto_2:"",
        contacto_3:"",
        Barrio_idBarrio:"",      
        
      };
    },
    methods: {
      insertar_cliente() {
       
       let datos={
        nit:this.nit,
        razon_social:this.razon_social,
        direccion:this.direccion,
              barrio:this.barrio,
              secretaria:this.secretaria,
              contacto_1:this.contacto_1,
              contacto_2:this.contacto_2,
              contacto_3:this.contacto_3,
              Barrio_idBarrio:this.Barrio_idBarrio
             };
        axios.post("https://citav.ibingcode.com/public/inserta_cliente",datos).then((result) => {
     //if (result.data.statu){
       //alert('Funcionario creado correctamente');
      //alert(result.data.DATOS); 
      let resp = JSON.parse(result.data);
      this.$swal.fire(resp.CODIGO == 0 ? 'EXITOSO' : 'ERROR', resp.DATOS, resp.CODIGO == 0 ? 'success' : 'error')
     //}else{
       //alert('Error insertando funcionario');
       
     //}
   });
        
      },
      
    },
        computed: {
         toggleMessage : function() { 
            return this.isRegister ? this.stateObj.register.message : this.stateObj.login.message }
      },
      
   
      
  };
  </script>