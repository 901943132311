<template>
    <div>
      <v-toolbar flat color="#263238" dark fixed>
        <v-toolbar-title>CIUDADES</v-toolbar-title>
        <v-divider
          class="mx-2"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{on}">
            <v-btn style="color:rgb(247, 237, 237)" @click="accion='N'" color="#F57C00" dark class="mb-2" v-on="on">Nueva Ciudad</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{formTitle}}</span>
            </v-card-title>
  
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm6 md4>
                    <v-text-field v-model="editedItem.idciudad" label="ID_CIUDAD"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md4>
                    <v-text-field v-model="editedItem.nombre" label="NOMBRE"></v-text-field>
                  </v-flex>          
                              
                </v-layout>
              </v-container>
            </v-card-text>
  
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
      :headers="headers"
      :items="ciudades" 
      class="elevation-1"
      >
      <template  v-slot:[`item.actions`]="{item}"
      
      >
      <v-btn color="#F57C00" fab small dark>
      <v-icon @click="editItem(item);accion='U'">mdi-pencil</v-icon>
      </v-btn>
      </template>    

  
    </v-data-table>
    </div>
  </template>
  <script>
  import axios from 'axios';
  export default {
    data: () => ({
      dialog: false,
      headers: [
       
        { text: 'ID', value: 'idciudad' },
        { text: 'CIUDAD', value: 'nombre' },
        { text: 'ACCION', value: 'actions' }
      
      ],
      ciudades:[],
      editedIndex: -1,
      accion:'N',
      editedItem: {
      idciudad: '',
      nombre: ''
      
    },
    username: "",
       password: "",
       confirmPassword: "",
       isRegister : false,
       errorMessage: ""
    
    }),
created(){
this.ListarCiudad();
},
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva Ciudad' : 'Editar Ciudad'
      }
    },
    
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },  

    methods: {
      

      ListarCiudad() {
        axios.get("https://citav.ibingcode.com/public/listarciudad").then((result) => {
        this.ciudades=result.data;
   
  });
      }, 
      editItem (item) {
        console.log(item)
      this.editedIndex = this.ciudades.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
     },
      close () {
      this.dialog = false
      this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
      })
    },
    save () {
      
      let datos={
             idciudad:this.editedItem.idciudad,
             nombre:this.editedItem.nombre,
             accion:this.accion
            };
       axios.post("https://citav.ibingcode.com/public/save", datos).then((result) => {
        let resp = JSON.parse(result.data);
      this.$swal.fire(resp.CODIGO == 0 ? 'EXITOSO' : 'ERROR', resp.DATOS, resp.CODIGO == 0 ? 'success' : 'error')
  });
    },
    }
  }
</script>