<template>
    <div>
      <v-toolbar flat color="#263238" dark fixed>
        <v-toolbar-title>CLIENTE</v-toolbar-title>
        <v-divider
          class="mx-2"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{on}">
     <v-btn class="mx-2" fab small dark color="#F57C00" @click="accion='N'"  v-on="on">
      <v-icon dark>
        mdi-plus
      </v-icon>
    </v-btn>
            <!-- <v-btn style="color:rgb(247, 237, 237)" @click="accion='N'" color="#F57C00" dark class="mb-2" v-on="on">Nuevo Cliente</v-btn> -->
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{formTitle}}</span>
            </v-card-title>
  
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm6 md4>
                    <v-text-field v-model="editedItem.idcliente" label="Nit"></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 md4>
                    <v-text-field v-model="editedItem.razon_social" label="Razon_Social"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md4>
                    <v-text-field v-model="editedItem.direccion" label="Direccion"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md4>
                    <v-text-field v-model="editedItem.secretaria" label="Secretaria"></v-text-field>
                  </v-flex>
                  
                  <v-flex xs12 sm6 md4>
                    <v-text-field v-model="editedItem.contacto_1" label="contacto_1"></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 md4>
                    <v-text-field v-model="editedItem.contacto_2" label="contacto_2"></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 md4>
                    <v-text-field v-model="editedItem.contacto_3" label="contacto_3"></v-text-field>
                  </v-flex>

                  
                  <v-flex xs12 sm6 md4>
            <template>
            <div>
            <v-select
            v-model="editedItem.ciudades"

            :items="ciudades"
            density="Ciudad"
            label="Ciudad"
            item-text="nombre"
            item-value="idciudad"
            @change="getbarrios(editedItem.ciudades)"
            ></v-select>
           </div>
           </template>
                  </v-flex>

                  <v-flex xs12 sm6 md4>
            <template>
            <div>
            <v-select
            v-model="editedItem.barrios"

            :items="barrios"
            density="barrios"
            label="barrios"
            item-text="nombre"
            item-value="id_barrio"
            ></v-select>
           </div>
           </template>
                  </v-flex>
                              
                </v-layout>
              </v-container>
            </v-card-text>
  
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="insertar_cliente"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
      :headers="headers"
      :items="clientes" 
      class="elevation-1"
      >
      <template  v-slot:[`item.actions`]="{item}"
      
      >
      <v-btn color="#F57C00" fab small dark>
      <v-icon @click="editItem(item);accion='U'">mdi-pencil</v-icon>
      </v-btn>
      </template>    

  
    </v-data-table>
    </div>
  </template>
  <script>
  import axios from 'axios';
  export default {
    data: () => ({
      dialog: false,
      headers: [
       
        { text: 'NIT', value: 'idcliente' },
        { text: 'NOMBRE', value: 'razon_social' },
        { text: 'DIRECCION', value: 'direccion' },
        { text: 'SECRETARIA', value: 'secretaria' },
        { text: 'CONTACTO_1', value: 'contacto_1' },
        { text: 'BARRIO', value: 'nombre' },
        { text: 'ACCION', value: 'actions' }
      
      ],
      clientes:[],
      ciudades:[],
      barrios:[],
      editedIndex: -1,
      accion:'N',
      editedItem: {
      idciudad: '',
      nombre: ''
      
    },     
    }),
created(){
this.ListarCliente();
this.ListarCiudad();
this.getbarrios();
},
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo Cliente' : 'Editar Cliente'
      }
    },
    
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },  

    methods: {
      

      ListarCliente() {
        axios.get("https://citav.ibingcode.com/public/listarcliente").then((result) => {
        this.clientes=result.data;
   
  });
      }, 
      ListarCiudad() {
        axios.get("https://citav.ibingcode.com/public/listarciudad").then((result) => {
        this.ciudades=result.data;
   
  });
      },      
      getbarrios(){

        let datos={
             idciudad:this.editedItem.ciudades
             
            };
        axios.post("https://citav.ibingcode.com/public/getbarrios",datos).then((result) => {
        this.barrios=result.data;
   
  });
      },
      editItem(item) {
      this.editedIndex = this.clientes.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
     },
      close () {
      this.dialog = false
      this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
      })
    },
    insertar_cliente() {
       
       let datos={
        nit:this.idcliente,
        razon_social:this.razon_social,
        direccion:this.direccion,
              barrio:this.barrio,
              secretaria:this.secretaria,
              contacto_1:this.contacto_1,
              contacto_2:this.contacto_2,
              contacto_3:this.contacto_3,
              Barrio_idBarrio:this.Barrio_idBarrio
             };
        axios.post("https://citav.ibingcode.com/public/inserta_cliente",datos).then((result) => {
     //if (result.data.statu){
       //alert('Funcionario creado correctamente');
      //alert(result.data.DATOS); 
      let resp = JSON.parse(result.data);
      this.$swal.fire(resp.CODIGO == 0 ? 'EXITOSO' : 'ERROR', resp.DATOS, resp.CODIGO == 0 ? 'success' : 'error')
     //}else{
       //alert('Error insertando funcionario');
       
     //}
   });
        
      },
    }
  }
</script>