<template>

    
  <div>
    <v-toolbar flat color="#263238" dark fixed>
      <v-toolbar-title>FUNCIONARIO</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
    </v-toolbar>
      <v-card>
        <v-card-text>
            <v-container grid-list-md>
                
                  
<form ref="form"
 @submit.prevent="insertar_funcionario()">
 <v-layout wrap>
 <v-flex xs12 sm6 md4>
 <v-text-field
    v-model="identificacion"
      name="identificacion"
      label="identificacion"
      type="text"
      placeholder="identificacion"
      required
   ></v-text-field>   
  </v-flex>
  <v-flex xs12 sm6 md4>
 <v-text-field
    v-model="usuario"
      name="usuario"
      label="usuario"
      type="text"
      placeholder="usuario"
      required
   ></v-text-field>
  </v-flex>
  <v-flex xs12 sm6 md4>
   
    <v-text-field
    v-model="nombre"
      name="nombre"
      label="nombre"
      type="text"
      placeholder="nombre"
      required
   ></v-text-field>
  </v-flex>
  <v-flex xs12 sm6 md4>
   <v-text-field
    v-model="pass"
      name="pass"
      label="pass"
      type="pass"
      placeholder="pass"
      required
   ></v-text-field>
  </v-flex>
  <v-flex xs12 sm6 md4>
   <v-text-field
    v-model="cargo"
      name="cargo"
      label="cargo"
      type="cargo"
      placeholder="cargo"
      required
   ></v-text-field>
  </v-flex>
  <!-- <v-flex xs12 sm6 md4>
   <v-text-field
    v-model="id_rol"
      name="rol"
      label="rol"
      type="rol"
      placeholder="rol"
      required
   ></v-text-field>
  </v-flex> -->

  <v-flex xs12 sm6 md4>
    <v-select
          v-model="select"
          :hint="`${select.Perfil}`"
          :items="items"
          item-text="Perfil"
          item-value="value"
          label="Select"
          persistent-hint
          return-object
          single-line
        ></v-select>
                  </v-flex>
  
   <!-- <div class="red--text"> </div> -->
   <v-btn type="submit" class="mt-4" color="#F57C00" style="color:white" value="log in">Crear Funcionario
    <!-- {{isRegister ? stateObj.register.name : stateObj.login.name}} -->
  </v-btn>
   <!-- <div class="grey--text mt-4" >
     
   </div> -->
  </v-layout>
</form>
</v-container>
</v-card-text> 
</v-card>     
</div>
 </template>
 <script>
 import axios from 'axios';
  export default {
    name: "App",
    data() {
      return {
        identificacion:"",
        usuario: "",
        nombre: "" ,
        pass: "",
        cargo: "" ,
        id_rol: "" ,
        select: { Perfil: 'Administrador', value: '1' },
        items: [
          { Perfil: 'Administrador', value: '1' },
          { Perfil: 'Inspector', value: '2' },

        ],     
        
      };
    },
    methods: {
      insertar_funcionario() {
       
       let datos={
        identificacion:this.identificacion,
        usuario:this.usuario,
              nombre:this.nombre,
              pass:this.pass,
              cargo:this.cargo,
              id_rol:this.select,
             };
        axios.post("https://citav.ibingcode.com/public/inserta_funcionario",datos).then((result) => {
     //if (result.data.statu){
       //alert('Funcionario creado correctamente');
       let resp = JSON.parse(result.data);
      this.$swal.fire(resp.CODIGO == 0 ? 'EXITOSO' : 'ERROR', resp.DATOS, resp.CODIGO == 0 ? 'success' : 'error')
     //}else{
       //alert('Error insertando funcionario');
       
     //}
   });
        
      },
      
    },
        computed: {
         toggleMessage : function() { 
            return this.isRegister ? this.stateObj.register.message : this.stateObj.login.message }
      },
      
   
      
  };
  </script>