<template >
          <v-card color="#F5F5F5"
    class="mx-auto"
    width="300"
    
  >
    <v-list >

      
      <!-- <v-list-item :to="'/dashboard'" style="color:black">
        <v-list-item-icon>
          <v-icon >mdi-home</v-icon>
        </v-list-item-icon>

        <v-list-item-title >DASHBOARD</v-list-item-title>
        

      </v-list-item>
      
      <v-list-item :to="'/listarciudad'" style="color:black">
        <v-list-item-icon>
          <v-icon >location_city</v-icon>
        </v-list-item-icon>

        <v-list-item-title>CIUDADES</v-list-item-title>
      </v-list-item>

      <v-list-item :to="''" style="color:black">
        <v-list-item-icon>
          <v-icon>car_crash</v-icon>
        </v-list-item-icon>

        <v-list-item-title>VEHICULOS</v-list-item-title>
      </v-list-item>

      <v-list-item :to="'/crearfuncionario'" style="color:black">
        <v-list-item-icon>
          <v-icon>manage_accounts</v-icon>
        </v-list-item-icon>

        <v-list-item-title>FUNCIONARIOS</v-list-item-title>
      </v-list-item>
      <v-list-item :to="'/listarcliente'" style="color:black">
        <v-list-item-icon>
          <v-icon>warehouse</v-icon>
        </v-list-item-icon>

        <v-list-item-title>CLIENTES</v-list-item-title>
      </v-list-item>
      

      <v-list-item :to="'proyectos'" style="color:black">
        <v-list-item-icon>
          <v-icon>devices</v-icon>
        </v-list-item-icon>

        <v-list-item-title>PROYECTOS</v-list-item-title>
      </v-list-item>

      <v-list-item :to="''" style="color:black">
        <v-list-item-icon>
          <v-icon>tablet_android</v-icon>
        </v-list-item-icon>

        <v-list-item-title>INSPECCIONES</v-list-item-title>
      </v-list-item>

      <v-list-item :to="'cargarlista'" style="color:black">
        <v-list-item-icon>
          <v-icon>fact_check</v-icon>
        </v-list-item-icon>

        <v-list-item-title>CARGAR LISTA</v-list-item-title>
      </v-list-item> -->
       
                    <v-list-item :to="navLink.route" v-for='navLink in navLinks' :key="navLink.text">
                        <v-list-item-icon>
                            <v-icon>{{ navLink.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title >{{ navLink.text }}</v-list-item-title>
                    </v-list-item>                  
                
                <v-list-group  
                :value="false" 
                prepend-icon="mdi-cog"
                >
                <template  v-slot:activator> 
            <v-list-item-title>CONFIGURACION</v-list-item-title>
          </template>
                  
                    <v-list-item  :to="navLink2.route" v-for='navLink2 in navLinks2' :key="navLink2.text" color="#515A5A" >
                        <v-list-item-icon >
                            <v-icon>{{ navLink2.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title >{{ navLink2.text }}</v-list-item-title>
                    </v-list-item>
                  
                   
                </v-list-group>     

    
    
    </v-list>

  </v-card>
</template>

<script>
export default {
data: () => ({
  

  navLinks: [
                { icon: "mdi-home", text: "DASHBOARD", route: "/dashboard" },
                { icon: "devices", text: "PROYECTOS", route: "/proyectos" },
                { icon: "tablet_android", text: "INSPECCIONES", route: "/inspecciones" }, 
                { icon: "mdi-note-text-outline", text: "RUNT", route: "/runt"},
                { icon: "car_crash", text: "PERITAJE", route: "/infovehiculo"},                 
            
            ],
 navLinks2: [              
              
                        {  icon: "mdi-home", text: "CIUDADES", route: "/listarciudad" },
                        {  icon: "manage_accounts", text: "FUNCIONARIOS", route: "/crearfuncionario" },
                        {  icon: "warehouse", text: "CLIENTES", route: "/listarcliente" },
                        {  icon: "mdi-file-document-edit", text: "DOCUMENTOS", route: "/listardocumento" }
                 
            ]
     
    })
    
}
    
 
</script>


